<template>
  <div class="main__content statistics">
    <Table :content="statistic_table" class="statistics__table" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueI18n from '@/i18n';
import Table from '@/elements/Table';
export default {
  components: {
    Table,
  },
  data() {
    return {
      statistic_table: [
        {
          uuid: null,
          tourism_type: VueI18n.t('statistic.outbound_tourism'),
          period: null,
          created_at: null,
          updated_at: null,
          isExist: false,
          props: {
            type_name: 'outbound_tourism',
            quarter: null,
            year: null,
          },
        },
        {
          uuid: null,
          tourism_type: VueI18n.t('statistic.inbound_tourism'),
          period: null,
          created_at: null,
          updated_at: null,
          isExist: false,
          props: {
            type_name: 'inbound_tourism',
            quarter: null,
            year: null,
          },
        },
        {
          uuid: null,
          tourism_type: VueI18n.t('statistic.tax_revenues'),
          period: null,
          created_at: null,
          updated_at: null,
          isExist: false,
          props: {
            type_name: 'tax_revenues',
            quarter: null,
            year: null,
          },
        },
      ],
    };
  },

  async created() {
    await this.$store.dispatch('getTourismReportsTableContentAdmin');
    await this.setTableData();
  },

  computed: {
    ...mapGetters(['getTourismReportsTableAdmin']),
  },

  methods: {
    setTableData() {
      if (!!this.getTourismReportsTableAdmin?.length) {
        this.getTourismReportsTableAdmin.map((el) => {
          this.statistic_table.map((t) => {
            if (el.type_en === t.props.type_name) {
              t.uuid = el.uuid;
              t.period = el.period;
              t.created_at = el.created_at;
              t.updated_at = el.updated_at;
              t.isExist = true;
              t.props.quarter = el.quarter;
              t.props.year = el.year;
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.statistics__table
  margin-top: 50px
</style>
